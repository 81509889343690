var data = {
    "0": {
        "param_order": [
            "0", 
            "1", 
            "2", 
            "4", 
            "5", 
            "6", 
            "7", 
            "8", 
            "9", 
            "10", 
            "11", 
            "12", 
            "13", 
            "14", 
            "15", 
            "16", 
            "17", 
            "18", 
            "19", 
            "20", 
            "21", 
            "22", 
            "23", 
            "24", 
            "25", 
            "27", 
            "28", 
            "29", 
            "30", 
            "31", 
            "32", 
            "33"
        ], 
        "parameters": {
            "0": {
                "name": {
                    "label": "durum", 
                    "translation": {
                        "en": "status", 
                        "tr": "durum"
                    }, 
                    "value": "0"
                }, 
                "options": [
                    {
                        "label": "pasif", 
                        "translation": {
                            "en": "passive", 
                            "tr": "pasif"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "aktif", 
                        "translation": {
                            "en": "active", 
                            "tr": "aktif"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "sipariş", 
                        "translation": {
                            "en": "order", 
                            "tr": "sipariş"
                        }, 
                        "value": "2"
                    }, 
                    {
                        "label": "iptal", 
                        "translation": {
                            "en": "cancel", 
                            "tr": "iptal"
                        }, 
                        "value": "3"
                    }, 
                    {
                        "label": "sonlandırıldı", 
                        "translation": {
                            "en": "terminated", 
                            "tr": "sonlandırıldı"
                        }, 
                        "value": "4"
                    }, 
                    {
                        "label": "sepete eklendi", 
                        "translation": {
                            "en": "add to shopcart", 
                            "tr": "sepete eklendi"
                        }, 
                        "value": "5"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "1": {
                "name": {
                    "label": "ad", 
                    "translation": {
                        "en": "name", 
                        "tr": "ad"
                    }, 
                    "value": "1"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "10": {
                "name": {
                    "label": "iptal tarihi", 
                    "translation": {
                        "en": "cancel date", 
                        "tr": "iptal tarihi"
                    }, 
                    "value": "10"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "11": {
                "name": {
                    "label": "aktivasyon tarihi", 
                    "translation": {
                        "en": "activation date", 
                        "tr": "aktivasyon tarihi"
                    }, 
                    "value": "11"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "12": {
                "name": {
                    "label": "sonlanma tarihi", 
                    "translation": {
                        "en": "termination date", 
                        "tr": "sonlanma tarihi"
                    }, 
                    "value": "12"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "13": {
                "name": {
                    "label": "pasife alınma tarihi", 
                    "translation": {
                        "en": "passive date", 
                        "tr": "pasife alınma tarihi"
                    }, 
                    "value": "13"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "14": {
                "name": {
                    "label": "sepete ekleyen kullanıcı", 
                    "translation": {
                        "en": "added to shopcart by", 
                        "tr": "sepete ekleyen kullanıcı"
                    }, 
                    "value": "14"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "15": {
                "name": {
                    "label": "sipariş eden kullanıcı", 
                    "translation": {
                        "en": "ordered by", 
                        "tr": "sipariş eden kullanıcı"
                    }, 
                    "value": "15"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "16": {
                "name": {
                    "label": "iptal eden kullanıcı", 
                    "translation": {
                        "en": "cancelled by", 
                        "tr": "iptal eden kullanıcı"
                    }, 
                    "value": "16"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "17": {
                "name": {
                    "label": "aktive eden kullanıcı", 
                    "translation": {
                        "en": "activated by", 
                        "tr": "aktive eden kullanıcı"
                    }, 
                    "value": "17"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "18": {
                "name": {
                    "label": "sonlandıran kullanıcı", 
                    "translation": {
                        "en": "terminated by", 
                        "tr": "sonlandıran kullanıcı"
                    }, 
                    "value": "18"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "19": {
                "name": {
                    "label": "pasife alan kullanıcı", 
                    "translation": {
                        "en": "passived by", 
                        "tr": "pasife alan kullanıcı"
                    }, 
                    "value": "19"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "2": {
                "name": {
                    "label": "özellik", 
                    "translation": {
                        "en": "feature", 
                        "tr": "özellik"
                    }, 
                    "value": "2"
                }, 
                "param_order": [
                    "3", 
                    "26"
                ], 
                "parameters": {
                    "26": {
                        "name": {
                            "label": "özellik tipi", 
                            "translation": {
                                "en": "feature type", 
                                "tr": "özellik tipi"
                            }, 
                            "value": "26"
                        }, 
                        "options": [
                            {
                                "label": "genel", 
                                "translation": {
                                    "en": "general", 
                                    "tr": "genel"
                                }, 
                                "value": "0"
                            }, 
                            {
                                "label": "anahtar bilgi", 
                                "translation": {
                                    "en": "key", 
                                    "tr": "anahtar bilgi"
                                }, 
                                "value": "1"
                            }, 
                            {
                                "label": "müşteri deneyimi", 
                                "translation": {
                                    "en": "customer exprerience", 
                                    "tr": "müşteri deneyimi"
                                }, 
                                "value": "2"
                            }, 
                            {
                                "label": "bağlantı", 
                                "translation": {
                                    "en": "link", 
                                    "tr": "bağlantı"
                                }, 
                                "value": "3"
                            }, 
                            {
                                "label": "kim kullanabilir", 
                                "translation": {
                                    "en": "who can use", 
                                    "tr": "kim kullanabilir"
                                }, 
                                "value": "4"
                            }
                        ], 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "select"
                    }, 
                    "3": {
                        "name": {
                            "label": "özellik başlığı", 
                            "translation": {
                                "en": "fea caption", 
                                "tr": "özellik başlığı"
                            }, 
                            "value": "3"
                        }, 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "text"
                    }
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "list_parameter"
            }, 
            "20": {
                "name": {
                    "label": "yakında kullanılabilir", 
                    "translation": {
                        "en": "available soon", 
                        "tr": "yakında kullanılabilir"
                    }, 
                    "value": "20"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "21": {
                "name": {
                    "label": "fiyatların ekranda gösterimi", 
                    "translation": {
                        "en": "prices on the screen", 
                        "tr": "fiyatların ekranda gösterimi"
                    }, 
                    "value": "21"
                }, 
                "options": [
                    {
                        "label": "hayır", 
                        "translation": {
                            "en": "no", 
                            "tr": "hayır"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "evet", 
                        "translation": {
                            "en": "yes", 
                            "tr": "evet"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "22": {
                "name": {
                    "label": "sona erme süre tipi", 
                    "translation": {
                        "en": "expiration time type", 
                        "tr": "sona erme süre tipi"
                    }, 
                    "value": "22"
                }, 
                "options": [
                    {
                        "label": "gün", 
                        "translation": {
                            "en": "day", 
                            "tr": "gün"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "ay", 
                        "translation": {
                            "en": "month", 
                            "tr": "ay"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "yıl", 
                        "translation": {
                            "en": "year", 
                            "tr": "yıl"
                        }, 
                        "value": "2"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "23": {
                "name": {
                    "label": "sona erme süre değeri", 
                    "translation": {
                        "en": "expiration time value", 
                        "tr": "sona erme süre değeri"
                    }, 
                    "value": "23"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "integer"
            }, 
            "24": {
                "name": {
                    "label": "sona erme süre değeri yazı ile", 
                    "translation": {
                        "en": "expiration time value by text", 
                        "tr": "sona erme süre değeri yazı ile"
                    }, 
                    "value": "24"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "25": {
                "name": {
                    "label": "organizasyon token", 
                    "translation": {
                        "en": "organization token", 
                        "tr": "organizasyon token"
                    }, 
                    "value": "25"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "textarea"
            }, 
            "27": {
                "name": {
                    "label": "organizasyon adı", 
                    "translation": {
                        "en": "organization name", 
                        "tr": "organizasyon adı"
                    }, 
                    "value": "27"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "28": {
                "name": {
                    "label": "organizasyon kullanıcı id", 
                    "translation": {
                        "en": "organization user id", 
                        "tr": "organizasyon kullanıcı id"
                    }, 
                    "value": "28"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "29": {
                "name": {
                    "label": "organizasyon kullanıcı adı", 
                    "translation": {
                        "en": "organization username", 
                        "tr": "organizasyon kullanıcı adı"
                    }, 
                    "value": "29"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "30": {
                "name": {
                    "label": "organizasyon önceliği", 
                    "translation": {
                        "en": "priority", 
                        "tr": "organizasyon önceliği"
                    }, 
                    "value": "30"
                }, 
                "options": [
                    {
                        "label": "yok", 
                        "translation": {
                            "en": "none", 
                            "tr": "yok"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "düşük", 
                        "translation": {
                            "en": "low", 
                            "tr": "düşük"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "orta", 
                        "translation": {
                            "en": "middle", 
                            "tr": "orta"
                        }, 
                        "value": "2"
                    }, 
                    {
                        "label": "yüksek", 
                        "translation": {
                            "en": "high", 
                            "tr": "yüksek"
                        }, 
                        "value": "3"
                    }, 
                    {
                        "label": "acil", 
                        "translation": {
                            "en": "urgent", 
                            "tr": "acil"
                        }, 
                        "value": "4"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "31": {
                "name": {
                    "label": "organizasyon görev tipi", 
                    "translation": {
                        "en": "task type", 
                        "tr": "organizasyon görev tipi"
                    }, 
                    "value": "31"
                }, 
                "options": [
                    {
                        "label": "bilgilendirme", 
                        "translation": {
                            "en": "to inform", 
                            "tr": "bilgilendirme"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "fatura", 
                        "translation": {
                            "en": "invoice", 
                            "tr": "fatura"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "genel", 
                        "translation": {
                            "en": "general", 
                            "tr": "genel"
                        }, 
                        "value": "2"
                    }, 
                    {
                        "label": "yazılım tasarımı yapma", 
                        "translation": {
                            "en": "software design", 
                            "tr": "yazılım tasarımı yapma"
                        }, 
                        "value": "3"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "32": {
                "name": {
                    "label": "paket tipi", 
                    "translation": {
                        "en": "bundle type", 
                        "tr": "paket tipi"
                    }, 
                    "value": "32"
                }, 
                "options": [
                    {
                        "label": "genel", 
                        "translation": {
                            "en": "general", 
                            "tr": "genel"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "çözüm", 
                        "translation": {
                            "en": "solution", 
                            "tr": "çözüm"
                        }, 
                        "value": "1"
                    }, 
                    {
                        "label": "platform", 
                        "translation": {
                            "en": "platform", 
                            "tr": "platform"
                        }, 
                        "value": "2"
                    }, 
                    {
                        "label": "kod şablonu", 
                        "translation": {
                            "en": "code template", 
                            "tr": "kod şablonu"
                        }, 
                        "value": "3"
                    }, 
                    {
                        "label": "wanalyzer", 
                        "translation": {
                            "en": "wanalyzer", 
                            "tr": "wanalyzer"
                        }, 
                        "value": "4"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "33": {
                "name": {
                    "label": "ürün kodu", 
                    "translation": {
                        "en": "product code", 
                        "tr": "ürün kodu"
                    }, 
                    "value": "33"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "4": {
                "name": {
                    "label": "adet", 
                    "translation": {
                        "en": "bundle count", 
                        "tr": "adet"
                    }, 
                    "value": "4"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "integer"
            }, 
            "5": {
                "name": {
                    "label": "mağaza paket client id", 
                    "translation": {
                        "en": "shop bundle client id", 
                        "tr": "mağaza paket client id"
                    }, 
                    "value": "5"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "6": {
                "name": {
                    "label": "mağaza paket wdmr id", 
                    "translation": {
                        "en": "shop bundle wdmr id", 
                        "tr": "mağaza paket wdmr id"
                    }, 
                    "value": "6"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "7": {
                "name": {
                    "label": "mağaza client id", 
                    "translation": {
                        "en": "shop client id", 
                        "tr": "mağaza client id"
                    }, 
                    "value": "7"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "8": {
                "name": {
                    "label": "sepete eklenme tarihi", 
                    "translation": {
                        "en": "shopcart add date", 
                        "tr": "sepete eklenme tarihi"
                    }, 
                    "value": "8"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "9": {
                "name": {
                    "label": "sipariş tarihi", 
                    "translation": {
                        "en": "order date", 
                        "tr": "sipariş tarihi"
                    }, 
                    "value": "9"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }
        }
    }, 
    "about": [], 
    "bmi_gfr": "no", 
    "calculate_eval": "no", 
    "checked_on_open": "no", 
    "date_ability": "yes", 
    "date_ability_limit": "none", 
    "date_ability_type": "date", 
    "date_ability_user_selection": "no", 
    "document_upload": "no", 
    "ending_date_ability": "yes", 
    "ending_date_ability_limit": "none", 
    "ending_date_ability_type": "date", 
    "ending_date_ability_user_selection": "no", 
    "favorite_show_mode": "yes", 
    "language": {
        "label": "Türkçe", 
        "value": "tr"
    }, 
    "model": "wisdom_data", 
    "module": [], 
    "name": {
        "label": "wdm_name !!!", 
        "translation": {
            "en": "user shopping cart bundle", 
            "tr": "kullanıcı alışveriş sepeti paket"
        }, 
        "value": "ly + Created Layer Id !!!"
    }, 
    "owner": {
        "label": "katman", 
        "value": "layer"
    }, 
    "owner_type": "layer", 
    "param_group": {
        "param_order": [
            "0"
        ], 
        "parameters": {
            "0": {
                "label": "Param", 
                "value": "0"
            }
        }
    }, 
    "parent": "ly + Created Layer Id", 
    "record_count": "many_times", 
    "status": "published", 
    "style": {
        "caption": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 12
        }, 
        "value": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 10
        }
    }, 
    "value": "ly + Created Layer Id", 
    "version": "1.0"
};
export { data }
